import { Injectable } from '@angular/core';
import { SfuiModalSheetService } from 'swire-frontline-ui/app';
@Injectable()
export class MockService {
  constructor(private modalSheetService: SfuiModalSheetService) {}

  public dismissAllModalSheet(): void {
    this.modalSheetService.dismissAll();
  }
}
