export function toAsciiString(buffer: ArrayBuffer): string {
  const chunkSize = 1024;
  let currentIndex = 0;
  let asciiStr = '';
  // String.fromCharCode will throw error if array is too large
  // Separate the array into small chunks to avoid error
  while (currentIndex < buffer.byteLength - 1) {
    const endIndex = currentIndex + chunkSize;
    const slice = buffer.slice(currentIndex, endIndex);
    currentIndex = endIndex;
    asciiStr += String.fromCharCode(...new Uint8Array(slice));
  }
  return asciiStr;
}
